
import SkeletonBox from '@/components/common/SkeletonBox.vue'
import SwiperWrap from '~/components/ds/common/SwiperCmp.vue'

export default {
  components: {
    SwiperWrap,
    SkeletonBox,
  }
}
