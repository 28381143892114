
import { myInjectionKey } from '@/mixin/ds/keys'
import { commonfunc } from '@/mixin/common'
export default {
  name: 'LINK',
  mixins: [commonfunc],
  inject: {
    injected: { from: myInjectionKey }
  },
  data () {
    return {
      naviShow: true,
      cateSwiperOpt: {
        slidesPerView: 11,
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        allowTouchMove: true
      },
      linkArr: []
    }
  },
  created () {
    // 카테고리 갯수 설정 노출개수 이하 시 navigation 숨김, 클릭 안됨
    if (this.injected.contentArr.details.length <= this.cateSwiperOpt.slidesPerView) {
      this.naviShow = false
      this.cateSwiperOpt.allowTouchMove = false
    }
  },
  mounted () {
    this.setLinkArr()
  },
  methods: {
    setLinkArr () {
      this.injected.contentArr.details.forEach((item) => {
        item.linkNm = this._.unescape(item.linkNm)
      })
    }
  }
}
