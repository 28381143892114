
export default {
  props: {
    idx: {
      type: Number,
      default: () => {
        return 0
      }
    },
    latitude: {
      type: Number,
      default: () => {
        return 0
      }
    },
    longitute: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  data () {
    return {
      map: null,
      innerValue: '',
      divValue: 'map'
    }
  },
  head () {
    return {
      script: [
        { src: '//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=c76ff8c45babd7968ccb5e957bb260e6&libraries=clusterer,drawing,services' }
      ]
    }
  },
  mounted () {

  },
  methods: {
    create () {
      this.innerValue = this.divValue + this.idx
      if (window.kakao && window.kakao.maps) {
        /* global kakao */
        kakao.maps.load(() => {
          // 카카오맵 API가 로딩이 완료된 후 지도의 기본적인 세팅 시작
          this.initMap()
        })
      } else {
        // 이미 카카오맵 API가 로딩되어 있다면 바로 지도를 생성
        this.initMap()
      }
    },
    initMap () {
      /* -------------------------------------------   지도 생성 Start ---------------------------------------- */
      const container = document.getElementById(this.innerValue)
      const options = {
        // 처음 지도의 위치를 lat, lng(위도, 경도) 값으로 설정
        center: new kakao.maps.LatLng(this.longitute, this.latitude), // 지도의 중심좌표
        level: 3 // 지도의 확대 레벨
      }

      if (container === null) {
        return
      }
      this.map = new kakao.maps.Map(container, options)

      // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성
      // const mapTypeControl = new kakao.maps.MapTypeControl()
      // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의
      // this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT)
      // 지도 확대 축소를 제어할 수 있는  줌 컨트롤 생성
      const zoomControl = new kakao.maps.ZoomControl()
      this.map.addControl(zoomControl, kakao.maps.ControlPosition.TOPRIGHT)

      /* -------------------------------------------   지도 생성 End ---------------------------------------- */

      /* -------------------------------------------   마커 표시 Start ---------------------------------------- */
      // 마커가 표시될 위치
      // const markerPosition = new kakao.maps.LatLng(this.latitude, this.longitute)
      // 마커를 생성
      const marker = new kakao.maps.Marker({
        position: options.center
      })

      // 마커가 지도 위에 표시되도록 설정
      marker.setMap(this.map)
      /* -------------------------------------------   마커 표시 End ---------------------------------------- */
    }
  }
}
