
import SkeletonBox from '@/components/common/SkeletonBox.vue'

export default {
  components: {
    SkeletonBox,
  },
  props: {
    unitType: {
      type: String,
      default: 'plan'
    },
  },
  data () {
    return {
      fullHeight: '100%',
      fullWidth: '100%',
      listForm: [],
      mainSwiperData: [
        {
          id: '1',
          imgUrl: '/images/tempLy/main_kv_img_1.png',
          videoUrl: '',
          txt01: '욕실에서만나는호캉스',
          txt02: '디즈니 겨울왕국2 노래하며 움직이는 올라프 인형 디즈니 겨울왕국2 노래하며 움직이는 올라프 인형'
        },
        {
          id: 'visual3',
          imgUrl: '/images/tempLy/main_kv_img_2.png',
          videoUrl: '',
          txt01: '시원한 여름 맞이',
          txt02: '디즈니 겨울왕국2 노래하며 움직이는 올라프 인형 디즈니 겨울왕국2 노래하며 움직이는 올라프 인형 움직이는 올라프 인형'
        },
        {
          id: 'visual4',
          imgUrl: '/images/tempLy/main_kv_img_3.png',
          videoUrl: '',
          txt01: '라탄 아이템 하나면 우리 집 분위기 완성',
          txt02: '디즈니 겨울왕국2 노래하며 움직이는 올라프 인형 디즈니 겨울왕국2 노래하며 움직이는 올라프 인형'
        }
      ],
      swiperMain: {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 28,
        observer: true,
        observeParents: true,
        loopAdditionalSlides: 1,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          // clickable: true,
          formatFractionCurrent (number) {
            return ('0' + number).slice(-2)
          },
          formatFractionTotal (number) {
            return ('0' + number).slice(-2)
          },
          renderFraction (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' + '<span class="' + totalClass + '"></span>'
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      cateSwiperOpt: {
        slidesPerView: 10,
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        allowTouchMove: true
      },
    }
  }
}
