
export default {
  name: 'Tab',
  components: {
  },
  props: {
    tabType: {
      type: String,
      default: 'button'
    },
    isActive: {
      type: String,
      default: '1'
    },
    tabsData: {
      type: [Object, Array],
      default: () => {
        return {}
      }
    },
    srchTxt: { // 검색
      type: String,
      default: ''
    },
    button: { // 스와이프 button type
      type: Boolean,
      default: false
    },
    /// 23.08.24 탭 타입 추가
    anchor: { // 스와이프 anchor type
      type: Boolean,
      default: false
    },
    /// 23.08.25 탭 타입 추가
    link: { // 스와이프 link type
      type: Boolean,
      default: false
    },
    radio: { // 스와이프 radio type
      type: Boolean,
      default: false
    },
    single: { // Panel X
      type: Boolean,
      default: false
    },
    giftCard: { // 상품권 탭 type
      type: Boolean,
      default: false
    },
    typeA: { // 탭 버튼 A type
      type: Boolean,
      default: false
    },
    typeB: { // 탭 버튼 A type
      type: Boolean,
      default: false
    },
    typeC: { // 탭 버튼 C type
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ruleForm: {
        name: '' // textArea 글자
      },
      active: '2', // 탭 index(활성화)
      tabIndex: ['1'], // 아코디언 index
      swiperOpt: { // swiper options
        init: false,
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 8,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        threshold: 30,
        watchOverflow: true, // 슬라이드가 1개 일 때 pager, button 숨김 여부 설정
        centeredSlides: false, // true시에 슬라이드가 가운데로 배치
        slideToClickedSlide: false // 해당 슬라이드 클릭시 슬라이드 위치로 이동
      }
    }
  },
  computed: {
    // swiper () {
    //   return this.$refs.tabSwipe.swiper
    // }
  },
  watch: {
    isActive: {
      handler (value) {
        console.log('isActive', value)
        this.active = value
      }
    },
    active () {
      this.$emit('tabChanged', this.active)
    }
  },
  created () {
    if (this.isActive !== undefined) {
      this.active = this.isActive
    }

    if (this.tabType === 'tabSwiper' && this.button === true && this.typeC === true) {
      this.swiperOpt.slidesPerGroup = 4
    }
  },
  mounted () {
    if (this.$refs.tabSwipe !== undefined) {
      this.$refs.tabSwipe.swiper.init()
    }
    this.slideWidth()
  },
  unmounted () {
  },
  methods: {
    prodNum (e) {
      if (typeof e === 'number') {
        if (e > 99999) {
          return '99,999+'
        } else {
          return Number(e).toLocaleString('ko-KR')
        }
      } else if (Number(e.replace(/[^0-9]/g, '')) > 99999) {
        return '99,999+'
      } else {
        return Number(e.replace(/[^0-9]/g, '')).toLocaleString('ko-KR')
      }
    },
    activeOn (e) {
      const index = this.tabsData.findIndex((item) => {
        return item.id === e.id
      })
      this.active = String(index + 1)
    },
    swiperBtnOn (e) {
      const index = this.tabsData.findIndex((item) => {
        return item.id === e.id
      })
      this.active = this.tabsData[index].id
    },
    slideWidth () { // slide 갯수에 따라 전체 넓이 체크 (필요시 호출)
      if (this.tabType === 'tabSwiper') {
        const swiperWidth = this.$refs.tabSwipe.$el.clientWidth
        const swiperItem = this.$refs.tabSwipe.$el.querySelectorAll('.swiper-slide')
        let widthSize = 0

        swiperItem.forEach((item) => {
          widthSize += item.clientWidth
        })

        if (widthSize < swiperWidth) {
          this.$refs.tabSwipe.$el.querySelector('.swiper-wrapper').classList.add('cont-center')
        } else {
          this.$refs.tabSwipe.$el.querySelector('.swiper-wrapper').classList.remove('cont-center')
        }
      }
    },
    slideToMove () {
      // if (this.tabType === 'tabSwiper' && this.button === true && this.typeC === true) {
      //   if (this.$refs.tabSwipe.swiper.clickedIndex !== undefined) {
      //     this.$refs.tabSwipe.swiper.slideTo(this.active - 1)
      //   }
      // }
    }
  }
}
