
import { mapGetters } from 'vuex'
import '@/assets/scss/0_common/_common.scss'
export default {
  name: 'LoadingBar',
  computed: {
    ...mapGetters('loading', ['isLoading'])
  },
  // props: {
  //   showLoadingBar: { type: Boolean, default: false }
  // }
}
