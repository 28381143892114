
import SkeletonBox from '@/components/common/SkeletonBox.vue'

export default {
  components: {
    SkeletonBox,
  },
  props: {
    unitType: {
      type: String,
      default: 'plan'
    },
  },
  data () {
    return {
    }
  }
}
