
export default {
  props: {
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      searchCondition: {},
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pagerCount: 11
      }
    }
  },

  methods: {
    pageChange (newPage) {
      this.pagination.currentPage = newPage
      this.$emit('pageChange', newPage)
    }
  }
}
