
import { commonfunc } from '~/mixin/common'
export default {
  name: 'CtgrLayer',
  components: {

  },
  mixins: [commonfunc],
  data () {
    return {
      activeTab: 'tab1', // 검색 순위 팝업 내부 탭
      active: '1', // 탭 index(활성화)
      fullCateVisible: false, // 카테고리 목록 mouseover 여부
      onFullCate: false, // 카테고리 목록 active
      activeIndex: 0, // 서브카테고리 액티브
      categoryList: [],
      reqMidCtgrList: [], // 소카 조회용 중카리스트
      tempList: [],
      categoryTree: []
    }
  },
  computed: {

  },
  async  mounted () {
    console.log('카테고리 레이어 그림')
    await this.selCtgrList()
    // 만들어진 카테고리 리스트를 이용해서 트리를 구성
    this.makeCategoryTree()
  },
  beforeDestroy () {
    //   window.removeEventListener('click')
  },
  methods: {
    makeCategoryTree () {
      this.categoryList.forEach((lgctgrItem, lgctgrIdx) => {
        const dept0 = {
          id: lgctgrItem.id,
          menu: lgctgrItem.menu,
          link: lgctgrItem.link,
          icon: lgctgrItem.icon,
          activeIcon: lgctgrItem.activeIcon,
          exhCtgrNo: lgctgrItem.exhCtgrNo,
          iconAlt: lgctgrItem.iconAlt,
          isActive: lgctgrItem.isActive,
          subCateArr: null
        }
        const mctgrList = lgctgrItem.subMenu
        const subCateArr = []
        mctgrList.forEach((mctgrItem, mctgrIdx) => {
          if (mctgrIdx < 6) {
            subCateArr.push([mctgrItem])
          } else {
            let targetNum = 0
            let minCount = 0
            subCateArr.forEach((arrItem, arrIdx) => {
              let count = 0
              arrItem.forEach((arrItemInner) => {
                count = count + arrItemInner.subList.length
              })

              if (arrIdx === 0 || count < minCount) {
                minCount = count
                targetNum = arrIdx
              }
            })
            subCateArr[targetNum].push(mctgrItem)
          }
        })
        dept0.subCateArr = subCateArr
        this.categoryTree.push(dept0)
      })
    },
    test () {
      this.categoryList[0].isActive = true
      this.activeIndex = 0
    },
    // 23.08.25 카테고리 함수 추가
    activateMenuItem (index) {
      this.activeIndex = index
      // 23.10.12 추가
      if (this.categoryList[0].isActive === true) {
        this.categoryList[0].isActive = false
      }

      // 액티브 될 때 하위 엘리먼트의 높이값을 가지고 옴

      this.categoryList[index].isActive = true
    },
    deactivateMenuItem (index) {
      this.activeIndex = -1
      // 23.10.12 추가
      if (this.categoryList[0].isActive === true) {
        this.categoryList[0].isActive = false
      }
      this.categoryList[index].isActive = false
    },
    isActive (index) {
      return this.activeIndex === index
    },
    cateActive (e) {
      if (e.target.closest('.full-category') !== null) {
        this.onFullCate = true
      } else {
        this.onFullCate = false
      }
      this.fullCateVisible = true
    },
    cateClose (e) {
      if (this.onFullCate === false) {
        this.fullCateVisible = false
      } else if (this.onFullCate && e.target.closest('.full-category') !== null) {
        this.fullCateVisible = false
      }
    },

    async  selCtgrList () {
      const payload = {
        exhCnrId: ''
      }
      await this.$AxiosBuilder
        .data(payload)
        .build()
        .post('/ds/ctgr')
        .then(async (res) => {
          const ctgrList = res.data.data
          ctgrList.forEach((item, i) => {
            const newItem = Object.assign({}, {
              id: i,
              menu: item.ctgrLayerNm,
              link: item.pcLinkUrl,
              icon: this.imgUrlMaker(item.pcomCtgrLayerAtchFileUrl),
              activeIcon: this.imgUrlMaker(item.pcomCtgrLayerAtchFileUrl),
              exhCtgrNo: item.exhCtgrNo,
              iconAlt: '',
              isActive: true,
              subMenu: []
            })
            // 대카먼저 넣어줌
            this.categoryList.push(newItem)

            const mdCtgrList = ctgrList[i].childCategories
            // console.table('mdCtgrList :::', mdCtgrList)
            // 중카까지 세팅
            mdCtgrList.forEach((item, index) => {
              const newMdItem = Object.assign({}, {
                subTitle: item.ctgrLayerNm,
                subMenuLink: item.pcLinkUrl,
                exhCtgrNo: item.exhCtgrNo,
                uprExhCtgrNo: item.uprExhCtgrNo,
                subList: []
              })
              this.reqMidCtgrList.push(item.exhCtgrNo) // 소카 조회용 중카리스트 만들기
              this.categoryList[i].subMenu.push(newMdItem)
            })
            //   console.log('카테고리 리스트 서브메뉴 확인 ', this.categoryList[i].subMenu)
          })

          await this.selSmallCtgrList(this.reqMidCtgrList)
        }).catch((err) => {
          console.log(err)
        })
    },
    async selSmallCtgrList (arrA) {
      // 소카테 조회
      const smParamData = {
        exhCtgrNos: arrA
      }
      await this.$AxiosBuilder
        .data(smParamData)
        .build()
        .post('/ds/ctgr')
        .then((res) => {
          const smallCtgrList = res.data.data
          smallCtgrList.forEach((item, index) => {
            const newItem = Object.assign({}, {
              link: item.pcLinkUrl,
              name: item.ctgrLayerNm,
              exhCtgrNo: item.exhCtgrNo,
              uprExhCtgrNo: item.uprExhCtgrNo
            })
            this.tempList.push(newItem)
          })

          this.setSmallCate(this.categoryList, this.tempList)
        }).catch((err) => {
          console.log(err)
        })
    },
    setSmallCate (arrA, arrB) {
      arrA.forEach((lItem, index) => {
        lItem.subMenu.forEach((mItem, mIndex) => {
          //   console.table('Mitem :::::', mItem.exhCtgrNo, '  index ::', index, '  mindex::', mIndex)
          for (let i = 0; i < arrB.length; i++) {
            if (mItem.exhCtgrNo === arrB[i].uprExhCtgrNo) {
              this.categoryList[index].subMenu[mIndex].subList.push(arrB[i])
            }
          }
        })
      })
    },
    categoryMove (str) {
      this.$emit('callCateClose')
      this.$router.push(str)
    }
  }
}
