
import { commonfunc } from '~/mixin/common'

export default {
  mixins: [commonfunc],
  props: {
    quickOn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      todayProduct: [],
      isBtnActive: false,
      isQuickList: false,
      popover: true, // 23.10.19 사라짐 추가
      srcAfter: 'cHJvakNkPURBSSZjaGFubmVsSWQ9MzI4',
      isChatbotIconShow: false,
      intervalChatbotIconShow: '',
      timeOutChatbotIconHidden: '',
      mbEid2: this.$store.getters['session/getMb'].mbEid2, // 고객 아이디를 화면에 담을 것.
      chatbotUrl: process.env.CHATBOT_URL,
    }
  },
  watch: {
    quickOn: {
      handler () {
        if (this.quickOn && this.popover) {
          // 23.10.19 사라짐 추가
          this.popoverCtr()
        }
      }
    },
    $route: {
      deep: true,
      handler () {
        this.getRecentlyProducts(JSON.parse(localStorage.getItem('visitedPdNo')))
      }
    }
  },
  created () {
    console.group('created' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
    this.addChatScript()
    this.mbEid2 = this.$store.getters['session/getMb'].mbEid2 // 고객 아이디를 화면에 담을 것.
    console.log('mbEid2:', this.$store.getters['session/getMb'].mbEid2)
    console.groupEnd()
  },
  mounted () {
    console.group('mounted' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
    this.getRecentlyProducts(JSON.parse(localStorage.getItem('visitedPdNo')))
    // this.activateChatbotIcon() jsTag.setAttribute('onload', this.activateChatbotIcon()) 에서 호출함.

    // this.mbEid2 = this.$store.getters['session/getmbEid2'] // 고객 아이디를 화면에 담을 것.
    this.mbEid2 = this.$store.getters['session/getMb'].mbEid2
    console.log('mbEid2:', this.$store.getters['session/getMb'].mbEid2)
    console.groupEnd()
  },
  methods: {
    fakeChatButtonClick () {
      console.group('fakeChatButtonClick' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())

      const mb = this.$store.getters['session/getMb']
      console.log('mbEid2:', this.$store.getters['session/getMb'].mbEid2)
      console.log('인증 진입 시  getMb::', mb)
      const returnUrlPath = this.$route.fullPath

      if (mb.mbEid2 === undefined) { // 로그인 정보가 없으면 로그인페이지로 이동
        // if (await this.confirm(this.$t('PD.LOGIN_ADULT'))) {  // 얼럿 미노출 되도록 변경
        // this.$router.push('/mb/mbb/SCR_MBB_0027A')
        this.$router.push({ name: 'mb-mbb-SCR_MBB_0097A', params: { returnUrl: returnUrlPath } })
        // }
      } else {
        document.getElementById('mbEid2').innerHTML = mb.mbEid2
        document.querySelector('#chatButton').click()
        this.toggleQuick()
      }
      console.groupEnd()
    },
    setButtonDisplay () {
      console.log('setButtonDisplay' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
      // metahub 에서 제공한 챗봇 아이콘을 미노출 처리
      const chatButton = document.querySelectorAll('.chat-icon-btn')
      try {
        if (chatButton.length > 0) {
          for (let i = 0; i < chatButton.length; i++) {
            console.log(i, ':', chatButton[i])
            if (chatButton[i].parentNode.nodeName === 'BODY') {
              chatButton[i].id = 'chatButton'
              document.querySelector('#chatButton').style.display = 'none'
            } else {
              chatButton[i].parentElement.removeChild(chatButton[i])
            }
          }
          if (document.querySelector('#chatButton').style.display === 'none') {
            clearInterval(this.timeOutChatbotIconHidden)
          }
        }
        if (document.querySelector('#chatButton').style.display === 'none') {
          clearInterval(this.timeOutChatbotIconHidden)
          return
        }
      } catch (error) {
        console.log('error:', error)
      }
      // metahub 에서 제공한 챗봇 아이콘을 미노출 처리
    },
    activateChatbotIcon () {
      console.group('call activateChatbotIcon() !!' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
      this.intervalChatbotIconShow = setInterval(() => {
        console.log('call loadChatbotIcon()' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
        this.loadChatbotIcon()
      }, 1000)
    },
    loadChatbotIcon () {
      console.group('call loadChatbotIcon()' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
      const chatJs = document.getElementById('metahubchatjs')
      if (chatJs !== null) {
        console.log(chatJs)
        try {
          if (!this.isChatbotIconShow) {
            // eslint-disable-next-line no-undef
            metachatLoad() // https://chat01widget.metahub.co.kr/chat.js 에 선언된 함수
            this.isChatbotIconShow = true
            clearInterval(this.intervalChatbotIconShow)
            this.timeOutChatbotIconHidden = setInterval(() => {
              this.setButtonDisplay()
            }, 100)
          }
        } catch (error) {
          console.log('error:', error)
          clearInterval(this.intervalChatbotIconShow)
        }
      }
      console.groupEnd()
    },
    addChatScript () {
      const env = process.env.VUE_APP_ENV
      console.log('env', env)
      const chatJs = document.getElementById('metahubchatjs')
      let jsTag = null
      let jssrc = ''
      try {
        if (chatJs === null) {
          jsTag = document.createElement('script')
          if (env === 'loc' || env === 'dev') {
            jssrc = 'importTest.js?'
          } else {
            jssrc = this.chatbotUrl + '?' + this.srcAfter // 'https://chat01widget.metahub.co.kr/chat.js?' + this.srcAfter
          }
          jssrc = this.chatbotUrl + '?' + this.srcAfter // jssrc = 'https://chat01widget.metahub.co.kr/chat.js?' + this.srcAfter
          jsTag.setAttribute('type', 'application/javascript')
          jsTag.setAttribute('src', jssrc)
          jsTag.setAttribute('charset', 'utf-8')
          jsTag.setAttribute('async', true)
          jsTag.setAttribute('id', 'metahubchatjs')
          jsTag.setAttribute('onload', this.activateChatbotIcon())
          document.head.prepend(jsTag)
          console.log('metahub.chatjs appended :' + '>>' + (new Date()).toLocaleString() + '.' + (new Date()).getMilliseconds())
        }
      } catch (error) {
        console.log(error)
      }
    },
    onErrorImg (e) {
      e.target.src = '/images/tempLy/today_view_thum.png'
    },
    openChat () {
      // 채팅상담 열기
      console.log('채팅상담 열기')
    },
    goScrollTop (e) {
      this.$emit('scrollTop')
    },
    async getRecentlyProducts (cookiePdNo) {
      this.todayProduct = []
      const payload = []
      if (this.isNotEmpty(cookiePdNo)) {
        cookiePdNo.forEach((item) => {
          const visitedPdNo = {
            pdNo: item.pdNo
          }
          payload.push(visitedPdNo)
        })
      }

      if (payload.length > 0) {
        const resData = await this.$AxiosBuilder
          .data(payload)
          .build()
          .post('/pdo/pdThumbSelList')
          .then((res) => {
            return res.data.data
          })

        if (resData !== null && resData !== undefined && resData.length > 0) {
          payload.forEach((reqItem) => {
            resData.forEach((resItem, resIndex) => {
              if (resIndex < 4) {
                if (reqItem.pdNo === resItem.pdNo) {
                  const recentlyProd = {
                    id: resItem.pdNo,
                    thumb: resItem.pdImgUrl,
                    name: resItem.pdNm
                  }
                  this.todayProduct.push(recentlyProd)
                }
              }
            })
          })
          console.log('최근본')
          console.log(this.todayProduct)
        }
      }
    },
    alarmClose (e) {
      e.target.closest('.alarm-tool').style.display = 'none'
    },
    toggleQuick () {
      this.isBtnActive = !this.isBtnActive
      this.isQuickList = !this.isQuickList
    },
    // 23.10.19 사라짐 추가
    popoverCtr () {
      setTimeout(() => {
        this.popover = false
      }, 3000)
    },
    goMembership () {
      const mbMngNo = this.$store.getters['session/getMbMngNo']
      if (!mbMngNo) {
        this.$router.push('/mb/mbb/SCR_MBB_0097A')
      } else {
        this.$router.push({ path: '/ms/msb/SCR_MSB_0001' })
      }
    }
  }
}
