
import ScrMbc011301 from '~/pages/ds/dsa/SCR_MBC_0113_01.vue'
import ScrMbc011302 from '~/pages/ds/dsa/SCR_MBC_0113_02.vue'
import ScrMbc011303 from '~/pages/ds/dsa/SCR_MBC_0113_03.vue'
import ScrMbc011304 from '~/pages/ds/dsa/SCR_MBC_0113_04.vue'
import ScrMbc011305 from '~/pages/ds/dsa/SCR_MBC_0113_05.vue'

export default {
  components: {
    ScrMbc011301,
    ScrMbc011302,
    ScrMbc011303,
    ScrMbc011304,
    ScrMbc011305
  },
  data () {
    return {
      cmpinfoAct: false,
      selectOptions: [{
        value: 'http://www.asunggroup.com',
        label: '아성'
      }, {
        value: 'http://www.asunghmp.com',
        label: '아성 HMP'
      }],
      selectValue: '',
      customerLinkMall: [
        {
          link: '/or/orb/SCR_ORB_0001',
          name: '주문조회'
        },
        {
          link: '/or/orb/SCR_ORB_0001',
          name: '취소/교환/반품 신청'
        }
      ],
      customerLinkMall2: [
        {
          link: '/cs/csl/SCR_CSL_0001',
          name: '1:1문의'
        },
        {
          link: '/cs/csm/SCR_CSM_0000',
          name: '공지사항'
        },
        {
          link: '/cs/csl/SCR_CSL_0004_I',
          name: '제휴문의'
        },
        {
          link: '/cs/csh/SCR_CSH_0000',
          name: '고객센터'
        }
      ],
      customerLinkStore: [
        {
          link: 'https://www.daiso.co.kr/cs/qna',
          name: '1:1문의'
        },
        {
          link: 'https://www.daiso.co.kr/cs/faq',
          name: '고객센터'
        },
        {
          link: 'https://www.daiso.co.kr/cs/allience',
          name: '제휴문의'
        }
      ],
      membershipLink: [
        {
          link: '/ds/member',
          name: '멤버십 소개'
        },
        {
          link: '/ms/mse/SCR_MSE_0001',
          name: '포인트 적립/사용내역'
        },
        {
          link: '/ms/msc/SCR_MSC_0001',
          name: '포인트 선물하기'
        },
        {
          link: '/ms/msb/SCR_MSB_0002',
          name: '멤버십 패밀리'
        }
      ],
      companyLink: [
        {
          link: 'https://www.daiso.co.kr/company/greetings',
          name: '기업 소개'
        },
        {
          link: 'https://www.daiso.co.kr/brand/story',
          name: '국민가게, 다이소'
        },
        {
          link: 'https://www.daiso.co.kr/winwin/quality',
          name: '경영이념'
        },
        {
          link: 'https://www.daiso.co.kr/talent/talent_image',
          name: '인재채용'
        },
        {
          link: 'https://www.daiso.co.kr/cs/supply',
          name: '납품문의'
        },
        {
          link: 'https://www.daiso.co.kr/cs/interior',
          name: '인테리어 업체 제휴문의'
        },
        {
          link: 'https://www.daiso.co.kr/cs/franchisee',
          name: '가맹문의'
        }
      ],
      snsItem: [
        {
          snsLink: 'https://blog.naver.com/daisoblog',
          snsImg: '/images/icon/footer_sns_blog.svg',
          snsName: '블로그'
        },
        {
          snsLink: 'https://www.instagram.com/daisolife/',
          snsImg: '/images/icon/footer_sns_instar.svg',
          snsName: '인스타'
        },
        {
          snsLink: 'https://www.facebook.com/daisolife?fref=ts',
          snsImg: '/images/icon/footer_sns_facebook.svg',
          snsName: '페이스북'
        },
        {
          snsLink: 'https://www.youtube.com/channel/UChz7dY8O1jTD8bXGJ8JgXEA',
          snsImg: '/images/icon/footer_sns_youtube.svg',
          snsName: '유튜브'
        },
        {
          snsLink: 'https://www.tiktok.com/@daisolife/',
          snsImg: '/images/icon/footer_sns_tiktok.png',
          snsName: '틱톡'
        }
      ]
    }
  },
  methods: {
    moveLink (link) {
      this.$router.push(link)
    },
    goFAQ () {
    //  alert('FAQ')
      this.$router.push('/cs/csi/SCR_CSI_0001')
    },
    goPartnership () {
      this.$router.push('/cs/csl/SCR_CSL_0004_I')
    },
    toggleCmpinfo () {
      this.cmpinfoAct = !this.cmpinfoAct
    },
    openTrm (agrTy) {
      if (agrTy === '01') {
        this.$refs.mbc011301.activeTab = '1'
      }
      this.$refs['mbc0113' + agrTy].selFooterTrmCleVerList()
      this.$refs['mbc0113' + agrTy].outerVisible = true
    },
    openBuySafe (agrTy) {
      this.$refs['mbc0113' + agrTy].sel()
      this.$refs['mbc0113' + agrTy].outerVisible = true
    },
    goFamilySite (e) {
      // console.log('테스트  :::', e)
      window.open(e)
      this.selectValue = ''
    },
    goSite (st) {
      switch (st) {
        case '01' : window.open('https://www.daiso.co.kr/brand/story')
          break
        case '02' : window.open('https://apps.apple.com/us/app/id1070715498') // 애플 앱스토어
          break
        case '03' : window.open('https://play.google.com/store/apps/details?id=com.uxlayer.wipoint') // 구글 앱스토어
          break
        case '99' : window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2138152063')
          break
      }
    },
    goHome () {
      if (this.$route.path === '/ds') {
        this.$router.go(this.$router.currentRoute)
      } else {
        this.$router.push({ path: '/ds' })
      }
    }
  }
}
