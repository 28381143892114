
import { commonfunc } from '~/mixin/common'
import { pushBannerGtmData } from '~/utils/gtm'
export default {
  name: 'SwiperCmp',
  components: {
  },
  mixins: [commonfunc],
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          auto: false,
          loop: false,
          navigation: false,
          pagination: false
        }
      }
    },
    bnnrList: { // 배너 데이터
      type: [Array, Object],
      default () { return [] }
    },
    swiperType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      swiperOpt: {
        slidesPerView: 1,
        loop: false,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
          // nextEl: '#button-next-relacionados.swiper-button-next',
          // prevEl: '#button-prev-relacionados.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-wrap .swiper-pagination',
          clickable: true,
          type: 'bullets', // 버튼 디자인 bullets" / "fraction"
          renderBullet (index, className) {
            return '<button type="button" aria-label="' + (index + 1) + '번 슬라이드로 이동하기" class="' + className + '"><span class="hiddenText">' + (index + 1) + '</span></button>'
          },
          renderFraction (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' + '<span class="' + totalClass + '"></span>'
          }
        },
        freeMode: false,
        slidesPerColumn: 1,
        grid: {
          rows: 1
        }
      },
      autoStatus: true
    }
  },
  computed: {
    formattedContent () {
      return this.bnnrList.map((item) => {
        const formattedText = item.hdcpy.replace(/\n/g, '<br>')
        return { ...item, hdcpy: formattedText }
      })
    }
  },
  created () {
    // 자동롤링
    if (this.options.auto === false) {
      this.swiperOpt.autoplay = this.options.auto
      this.autoStatus = false
    } else if (typeof this.options.auto === 'number') {
      this.swiperOpt.autoplay.delay = this.options.auto
      this.autoStatus = false
    }

    // 무한루프
    if (this.options.loop === false) {
      this.swiperOpt.loop = this.options.loop
    }

    // 인디케이터
    if (this.options.pagination === 'fraction') {
      this.swiperOpt.pagination.type = this.options.pagination
    }

    // freeMode
    if (this.options.freeMode === true) {
      this.swiperOpt.freeMode = this.options.freeMode
    }

    // slidesPerView
    if (this.options.slidesPerView !== undefined) {
      this.swiperOpt.slidesPerView = this.options.slidesPerView
    }

    // slidesPerColumn
    if (typeof this.options.slidesPerColumn === 'number') {
      this.swiperOpt.slidesPerColumn = this.options.slidesPerColumn
    }

    // slidesPerGroup
    if (typeof this.options.slidesPerGroup === 'number') {
      this.swiperOpt.slidesPerGroup = this.options.slidesPerGroup
    }

    // grid
    if (typeof this.options.grid === 'number') {
      this.swiperOpt.grid.rows = this.options.grid
    }

    // spaceBetween
    if (typeof this.options.spaceBetween === 'number') {
      this.swiperOpt.spaceBetween = this.options.spaceBetween
    }

    // direction
    if (this.options.direction === 'vertical') {
      this.swiperOpt.direction = this.options.direction
    }

    // console.log('swiperOptswiperOptswiperOpt', this.swiperOpt)
  },
  mounted () {
    if (this.bnnrList.length === 1) {
      this.swiperDestroy()
    }
    if (this.swiperType === 'oneline') {
      this.$refs.swiperEl.swiper.allowTouchMove = false
    }
  },
  methods: {
    formatText (text) {
      const formattedText = text.replace(/\n/g, '<br>')
      return this._.unescape(formattedText)
    },
    stopSwiper () {
      console.log('stop', this.$refs.swiperEl)
      this.$refs.swiperEl.swiper.autoplay.stop()
      this.autoStatus = false
    },
    playSwiper () {
      console.log('play')
      this.$refs.swiperEl.swiper.autoplay.start()
      this.autoStatus = true
    },
    swiperDestroy () {
      this.$refs.swiperEl.swiper.destroy()
    },
    videoOptions (src, thmn) {
      return {
        controls: false,
        autoplay: false,
        muted: true,
        loop: true, // 반복재생
        preload: 'auto',
        poster: thmn,
        sources: [
          {
            src,
            type: 'video/mp4'
          }
        ],
        playbackRates: [1]
      }
    },
    clickBanner (item) {
      pushBannerGtmData(item.pcomIconOrglFileNm)
      console.log('banrLinkMvmnTyCd ===', item.banrLinkMvmnTyCd)
      if (item.banrLinkMvmnTyCd === '02') {
        window.open(item.pcomLinkUrl)
      } else {
        this.$router.push(item.pcomLinkUrl)
      }
    }
  }
}
