import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=a774e996&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/home/jenkins/.jenkins/workspace/prd-daiso-fo-web/daiso_vue_fo/components/ds/materials/link.vue').default})
