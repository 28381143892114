
import * as ds from '~/scriptData/ds/dsCommon.js'
import { commonfunc } from '@/mixin/common.js'
export default {
  mixins: [commonfunc],
  props: {
    showTopBanner: {
      type: Boolean,
      default: false
    },
    accounts: {
      type: Array,
      default: () => { return [] }
    },
    bannerId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      topbaner: false,
      bannerItem: {},
      exhCmptmtId: '',
      bannerArr: []
    }
  },
  fetch () {
    this.getBannerImg()
  },
  watch: {
    showTopBanner: function (visible) {
      if (visible) {
        this.getBannerImg()
      }
    },
    topbaner: function (visible) {
      this.$emit('update:showTopBanner', visible)
    }

  },
  beforeDestroy () {
    this.topbaner = !this.topbaner
  },
  methods: {
    closetopBn () {
      // 브라우저 종료시까지 배너 닫기
      this.hideBanner()
      this.topbaner = !this.topbaner
    },
    async getBannerImg () {
      const banners = await ds.getMtrlsList(this.accounts, this.$api)
      if (banners.length > 0) {
        this.bannerItem = banners[0][0]
        this.topbaner = true
      }
    },
    hideBanner () {
      const date = new Date()
      date.setTime(date.getTime() + (24 * 60 * 60 * 1000))
      this.$cookiz.set('topAnimation', this.bannerId, { expires: date })
    },
    errorLogo (e) {
      e.target.src = '/images/tempLy/@temp_top_banner.png'
    },
    moveToUrl (url) {
      if (url && url !== '') {
        this.$router.push(url)
      }
    }
  }
}
